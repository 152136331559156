export default [
  {
    title: 'modules.Dashboard', /* <i18n path="modules.Dashboard"></i18n> */
    route: { name: 'dashboard', params: {} },
    icon: 'HomeIcon',
    resource: 'Geral',
    action: 'manager',

  },
  {
    title: 'modules.Notificações', /* <i18n path="modules.Notificações"></i18n> */
    route: { name: 'notification-list', params: {} },
    icon: 'BellIcon',
    resource: 'Geral',
    action: 'manager',
  },
]
