export default [
  {
    title: 'modules.sales.Vendas', /* <i18n path="modules.sales.Vendas"></i18n> */
    icon: 'FileTextIcon',
    children: [
      {
        title: 'modules.sales.Processos de venda', /* <i18n path="modules.sales.Processos de venda"></i18n> */
        route: { name: 'sales-listview-processos-venda', params: {} },
        resource: 'sales_listview_processos',
        action: 'list',
      },
      {
        title: 'modules.sales.Processos de arrendamento', /* <i18n path="modules.sales.Processos de arrendamento"></i18n> */
        route: { name: 'sales-listview-processos-arrendamento', params: {} },
        resource: 'sales_listview_processos',
        action: 'list',
      },
    ],
  },
]
