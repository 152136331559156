import menusGeral from './modules/geral'
import menusListings from './modules/listings'
import menusCRM from './modules/crm'
import menusSales from './modules/sales'
import menusFinancial from './modules/financial'
import menusGrowth from './modules/growth'
// import menusDesign from './modules/design'
import menusToolbox from './modules/toolbox'
import menusMarket from './modules/market'

export default [...menusGeral, ...menusListings, ...menusCRM, ...menusSales, ...menusFinancial, ...menusGrowth, ...menusToolbox, ...menusMarket]
