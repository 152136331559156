export default [
  {
    title: 'modules.listings.Angariações', /* <i18n path="modules.listings.Angariações"></i18n> */
    icon: 'HomeIcon',
    children: [
      // {
      //   title: 'modules.listings.Nova angariação', /* <i18n path="modules.listings.Nova angariação"></i18n> */
      //   route: { name: 'listings-new', params: {} },
      //   disabled: false,
      //   resource: 'listings',
      //   action: 'new',
      // },
      {
        title: 'modules.listings.Nova pré-angariação', /* <i18n path="modules.listings.Nova pré-angariação"></i18n> */
        route: { name: 'listings-pre-new', params: {} },
        disabled: false,
        resource: 'listings',
        action: 'new_pre',
      },
      {
        title: 'static.menuListingName', /* <i18n path="modules.listings.My"></i18n> */
        route: { name: 'listings-listview-list', params: {} },
        disabled: false,
        resource: 'listings',
        action: 'list_my',
      },
      {
        title: 'modules.listings.Pendentes', /* <i18n path="modules.listings.Pendentes"></i18n> */
        route: { name: 'listings-listview-pendentes', params: {} },
        disabled: false,
        resource: 'listings',
        action: 'manager',
      },
      {
        title: 'modules.listings.Do meu HUB', /* <i18n path="modules.listings.Do meu HUB"></i18n> */
        route: { name: 'listings-listview-myHUBLocation', params: {} },
        disabled: false,
        resource: 'listings_morada',
        action: 'list',
      },
      {
        title: 'static.menuListingNameHUB',
        route: { name: 'listings-listview-myHUB', params: {} },
        disabled: false,
        resource: 'listings_hub',
        action: 'list',
      },
      {
        title: 'modules.listings.Da rede', /* <i18n path="modules.listings.Da rede"></i18n> */
        route: { name: 'listings-listview-rede', params: {} },
        disabled: false,
        resource: 'listings_rede',
        action: 'list',
      },
      {
        title: 'modules.listings.Externas', /* <i18n path="modules.listings.Externas"></i18n> */
        route: { name: 'listings-listview-externas', params: {} },
        disabled: false,
        resource: 'listings_externa',
        action: 'list',
      },
      {
        title: 'modules.listings.Favoritos', /* <i18n path="modules.listings.Favoritos"></i18n> */
        route: { name: 'listings-favorites', params: {} },
        disabled: false,
        resource: 'listings_favoritos',
        action: 'list',
      },
      {
        title: 'modules.listings.Mapa', /* <i18n path="modules.listings.Mapa"></i18n> */
        route: { name: 'listings-full-maps-view', params: {} },
        disabled: false,
        resource: 'listings_mapa',
        action: 'list',
      },
      {
        title: 'modules.listings.Partilhas de site', /* <i18n path="modules.listings.Partilhas de site"></i18n> */
        route: null,
        disabled: false,
        resource: 'listings_shares',
        action: 'list',
        children: [
          {
            title: 'modules.listings.Minhas', /* <i18n path="modules.listings.Minhas"></i18n> */
            route: { name: 'listings-myShares', params: {} },
            disabled: false,
            resource: 'listings_shares_my',
            action: 'list',
          },
          {
            title: 'modules.listings.As minhas Angariações', /* <i18n path="modules.listings.As minhas Angariações"></i18n> */
            route: { name: 'listings-shares', params: {} },
            disabled: false,
            resource: 'listings_my_shares',
            action: 'list',
          },
          {
            title: 'static.menuShareAgentsNameHUB', /* <i18n path="modules.listings.Consultores"></i18n> */
            route: { name: 'listings-shared-byConsultant', params: {} },
            disabled: false,
            resource: 'listings_shares_agent',
            action: 'list',
          },
          {
            title: 'modules.listings.Consultores do meu HUB', /* <i18n path="modules.listings.Consultores do meu HUB"></i18n> */
            route: { name: 'listings-shared-byConsultantHUB', params: {} },
            disabled: false,
            resource: 'listings_shares_agent_hub',
            action: 'list',
          },
          {
            title: 'static.menuShareListingNameHUB', /* <i18n path="modules.listings.Angariações"></i18n> */
            route: { name: 'listings-shared', params: {} },
            disabled: false,
            resource: 'listings_shares_geral',
            action: 'list',
          },
          {
            title: 'modules.listings.Angariações do meu HUB', /* <i18n path="modules.listings.Angariações do meu HUB"></i18n> */
            route: { name: 'listings-shared-HUB', params: {} },
            disabled: false,
            resource: 'listings_shares_hub',
            action: 'list',
          },
        ],
      },
    ],
  },
]
