<template>
  <b-nav-item-dropdown
    v-if="$store.getters['auth/currentUser']"
    ref="dropdownUserOptionTop"
    right
    toggle-class="d-flex align-items-center dropdown-user-link"
    class="dropdown-user"
  >
    <template #button-content>
      <div class="d-sm-flex d-none user-nav">
        <p class="user-name font-weight-bolder mb-0">
          {{ $store.getters['auth/currentUser'].name }}
        </p>
        <span class="user-status">{{ $store.getters['auth/currentUser'].role }}</span>
      </div>
      <b-avatar
        size="40"
        :src="$store.getters['auth/currentUser'].image"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon
          v-if="!$store.getters['auth/currentUser'].name"
          icon="UserIcon"
          size="22"
        />
      </b-avatar>
    </template>

    <b-dropdown-form
      class="d-sm-none d-xs-block"
    >
      <b-container fluid>
        <b-row>
          <b-col cols="12">
            <p
              v-if="$store.getters['auth/currentUser'].name!==''"
              class="font-weight-bolder mb-1"
            >
              {{ $store.getters['auth/currentUser'].name }}
            </p>
            <p
              v-if="$store.getters['auth/currentUser'].email !== ''"
              class="font-weight-normal mb-50"
            >
              <feather-icon
                size="16"
                icon="MailIcon"
                class="mr-50"
              />
              {{ $store.getters['auth/currentUser'].email }}
            </p>
            <p
              v-if="$store.getters['auth/currentUser'].role !== ''"
              class="font-weight-normal mb-50"
            >
              <feather-icon
                size="16"
                icon="BriefcaseIcon"
                class="mr-50"
              />
              {{ $store.getters['auth/currentUser'].role }}
            </p>
          </b-col>
        </b-row>
      </b-container>
    </b-dropdown-form>

    <b-dropdown-form>
      <b-row class="locales-mobile ml-50 mr-50">
        <b-col
          v-for="localeObj in locales"
          :key="localeObj.locale"
          cols="4"
          class="text-center rounded p-50 cursor-pointer"
          :class="{'active': ((localeObj.locale === $i18n.locale) ? true : false )}"
          @click.stop.prevent="changeLocale(localeObj.locale)"
        >
          <b-img
            :src="localeObj.img"
            height="14px"
            width="22px"
            :alt="localeObj.locale"
          />
        </b-col>
      </b-row>
    </b-dropdown-form>

    <b-dropdown-divider />

    <b-dropdown-item
      link-class="d-flex align-items-center"
    >
      <b-button
        variant="primary"
        block
        @click.stop.prevent="logout"
      >
        <feather-icon
          size="16"
          icon="LogOutIcon"
          class="mr-50"
        />
        <span>{{ $t('Terminar sessão') }} </span>
      </b-button>
    </b-dropdown-item>

  </b-nav-item-dropdown>
</template>

<script>
import {
  BNavItemDropdown, BDropdownItem, BAvatar, BDropdownDivider, BDropdownForm, BImg, BRow, BCol, BButton, BContainer,
} from 'bootstrap-vue'
import { avatarText } from '@core/utils/filter'
import { mapGetters } from 'vuex'

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BDropdownForm,
    BImg,
    BRow,
    BCol,
    BAvatar,
    BButton,
    BContainer,
  },
  data() {
    return {
      avatarText,
    }
  },
  computed: {
    currentLocale() {
      return this.locales.find(l => l.locale === this.$i18n.locale)
    },
    ...mapGetters('auth', ['locales']),
  },
  methods: {
    async logout() {
      await this.$store
        .dispatch('auth/logout')
        .then(() => this.$router.push({ name: 'auth-login' }))
    },
    changeLocale(locale) {
      this.$i18n.locale = locale
      this.$store.dispatch('auth/setConfigAccountUser', { locale })
      this.$refs.dropdownUserOptionTop.hide()
    },
  },
}
</script>

<style lang="scss" scoped>
.locales-mobile .active{
  background: rgba(34, 41, 47, 0.05);
}

.dark-layout .locales-mobile .active{
  background: #3b4253;
}
</style>
