<template>
  <b-nav-item-dropdown
    ref="notificationDashboardIcon"
    class="dropdown-notification mr-25"
    menu-class="dropdown-menu-media"
    right
  >
    <template #button-content>
      <feather-icon
        :badge="notificationsBadge"
        badge-classes="bg-danger"
        class="text-body"
        icon="BellIcon"
        size="21"
      />
    </template>

    <li class="dropdown-menu-header">
      <div class="dropdown-header d-flex">
        <h4 class="notification-title mb-0 mr-auto">
          {{ $t('Notificações') }}
        </h4>
        <b-badge
          v-if="notificationsTotal > 0"
          pill
          variant="light-primary"
        >
          {{ notificationsTotal }} {{ $t('Novas') }}
        </b-badge>
      </div>
    </li>

    <vue-perfect-scrollbar
      class="scrollable-container media-list scroll-area"
      tagname="li"
      :settings="configSettingsScroll"
    >
      <div
        v-if="isNativeDevice===true"
        class="media d-flex align-items-center"
      >
        <h6 class="font-weight-bolder mr-auto mb-0">
          {{ $t('Permitir notificações') }}
        </h6>
        <b-form-checkbox
          v-model="activeNotificationPush"
          switch
          @change="changeAllowNotification"
        />
      </div>

      <div
        v-for="notification in listNotificationUser.value"
        :key="`notification-${notification.sw041s01}`"
      >
        <b-media
          :class="{'cursor-pointer': ((notification.link_app_mobile !== '') ? true : false)}"
          @click.stop.prevent="openNotification(notification.link_app_mobile, notification.sw041s01)"
        >
          <p class="media-heading">
            <span class="font-weight-bolder">
              {{ notification.sw041s03 || '' }}
            </span>
          </p>
          <small
            v-if="notification.sw041s07 !== ''"
            class="notification-text"
          >{{ notification.sw041s07 }}</small>
        </b-media>
      </div>

      <div v-if="listNotificationUser.value.length === 0">
        <b-media>
          <p class="media-heading p-1">
            {{ $t('Não tem notificações') }}
          </p>
        </b-media>
      </div>

    </vue-perfect-scrollbar>

    <li
      class="dropdown-menu-footer"
    >
      <b-button
        v-if="($route.name !== 'notification-list') && (listNotificationUser.value.length > 0)"
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="primary"
        block
        @click.stop.prevent="viewAllNotification"
      >{{ $t('Ver todas') }}
      </b-button>

      <b-button
        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
        variant="outline-secondary"
        block
        @click.stop.prevent="$refs.notificationDashboardIcon.hide()"
      >{{ $t('Fechar') }}
      </b-button>
    </li>

  </b-nav-item-dropdown>
</template>

<script>
import store from '@/store'
import {
  BNavItemDropdown, BBadge, BMedia, BButton, BFormCheckbox,
} from 'bootstrap-vue'
import VuePerfectScrollbar from '@/views/modules/components/vue-perfect-scrollbar.vue'
import Ripple from 'vue-ripple-directive'
import { onUnmounted, ref, computed } from '@vue/composition-api'
import dashboardModule from '@store-modules/dashboard/index'
import { showMsgRequest } from '@core-custom/mixins/geral'
import useCapacitor from '@core-custom/utils/capacitor'
import { configSettingsScroll } from '@core-custom/utils/ui'
import { Badge } from '@capawesome/capacitor-badge'

export default {
  components: {
    BNavItemDropdown,
    BBadge,
    BMedia,
    VuePerfectScrollbar,
    BButton,
    BFormCheckbox,
  },
  directives: {
    Ripple,
  },
  mixins: [showMsgRequest],
  computed: {
    isNativeDevice() {
      return this.$store.getters['capacitor/isNative'] || false
    },
    infoUserAuth() {
      return this.$store.getters['auth/currentUser']
    },
  },
  watch: {
    infoUserAuth: {
      handler(newVal) {
        if ((newVal !== null) && (newVal !== undefined) && ('auth' in newVal) && (newVal.auth === true)) {
          this.registerNotification()
          this.checkStatusNotificationUser()
        } else {
          clearInterval(this.exeSyncNotificationUser)
        }
      },
      immediate: true,
    },
  },
  beforeCreate() {
    this.$root.$on('notification::syncNotificationUser', () => {
      this.syncNotificationUser(this)
    })
  },
  beforeDestroy() {
    clearInterval(this.exeSyncNotificationUser)
    this.$root.$off('notification::syncNotificationUser')
  },
  methods: {
    registerNotification() {
      this.syncNotificationUser(this)

      // Executa a cada 5 minutos
      this.exeSyncNotificationUser = setInterval(() => {
        this.syncNotificationUser(this)
      }, 300000)
    },
    syncNotificationUser(self) {
      const oThis = self

      oThis.$store.dispatch(`${this.NOTIFICATION_MODULE_NAME}/getNotificationByUser`).then(async res => {
        const totalNotificationsBadge = ((res.total > 99) ? '+99' : res.total)
        oThis.notificationsTotal = res.total || 0
        oThis.notificationsBadge = totalNotificationsBadge
        oThis.notifications = res.notifications || []

        const result = await Badge.isSupported()
        if (result.isSupported === true) {
          await Badge.set({ count: totalNotificationsBadge })
        }
      }).catch(async error => {
        oThis.showMsgErrorRequest(error)
      })
    },
    viewAllNotification() {
      this.$router.push({ name: 'notification-list' })
      this.$refs.notificationDashboardIcon.hide()
    },
    async changeAllowNotification(val) {
      this.$root.$emit('app::loading', true)

      await this.$nextTick()

      if (useCapacitor.isNative() === true) {
        const checkStatus = await useCapacitor.checkPermissionNotificationDevice()
        if ((checkStatus !== null) && (checkStatus !== undefined) && ('receive' in checkStatus) && (checkStatus.receive === 'granted')) {
          this.disableNotificationPush = false

          await this.$store.dispatch('auth/registerPushNotification', { status: ((val === true) ? '' : 1) }).then(resStatus => {
            if (resStatus === true) {
              if (val === true) {
                this.activeNotificationPush = true
                this.showMsgSuccessRequest({ message: this.$t('Notificações foram ativadas') })
              } else {
                this.activeNotificationPush = false
                this.showMsgSuccessRequest({ message: this.$t('Notificações foram desativadas') })
              }
            } else if (resStatus === false) {
              this.activeNotificationPush = false
              if (val === 1) {
                this.showMsgErrorRequest({ message: this.$t('Problema ao ativar as notificações') })
              } else {
                this.showMsgErrorRequest({ message: this.$t('Problema ao desativar as notificações') })
              }
            }
          })
        } else {
          this.activeNotificationPush = false
          this.disableNotificationPush = true
          this.showMsgErrorRequest({ message: this.$t('Aceda ao menu definições > {NOME_APP} > Notificações > Permitir notificações', { NOME_APP: process.env.VUE_APP_TITLE }) })
          await this.$store.dispatch('auth/registerPushNotification', { status: 1 })
        }
      }

      this.$root.$emit('app::loading', false)
    },
    async checkStatusNotificationUser() {
      if (useCapacitor.isNative() === true) {
        const checkStatus = await useCapacitor.checkPermissionNotificationDevice()
        if ((checkStatus !== null) && (checkStatus !== undefined) && ('receive' in checkStatus) && (checkStatus.receive === 'granted')) {
          this.disableNotificationPush = false

          // Verifica se ja tem o registo do dispositivo e obtem o estado, caso nao exista e tenha permissoes cria a entrada
          await this.$store.dispatch('auth/getStatusDeviceNotificationPush').then(async resStatus => {
            if (resStatus.status === 1) {
              this.activeNotificationPush = true

              if (resStatus.existConfig === false) {
                await this.$store.dispatch('auth/registerPushNotification', { status: '' })
              }
            } else {
              this.activeNotificationPush = false

              if (resStatus.existConfig === false) {
                await this.$store.dispatch('auth/registerPushNotification', { status: 1 })
              }
            }
          })
        } else {
          await this.$store.dispatch('auth/registerPushNotification', { status: 1 })
          this.activeNotificationPush = false
          this.disableNotificationPush = true
        }
      }
    },
    async changeMark(idNotifi) {
      const formParams = new FormData()
      formParams.append('notif[]', idNotifi)

      this.$root.$emit('app::loading', true)
      await this.$store.dispatch(`${this.NOTIFICATION_MODULE_NAME}/changeMarkStatusNotification`, formParams).then(() => {
        this.$root.$emit('app::loading', false)
      })
    },
    async openNotification(url, notifID) {
      this.$refs.notificationDashboardIcon.hide()

      if (url !== '') {
        if (notifID !== '') {
          await this.changeMark(notifID)
        }

        if ((this.$router.currentRoute.path !== '') && (this.$router.currentRoute.path !== url)) {
          await this.$router.push(url)
        }
      }
    },
  },
  setup() {
    const NOTIFICATION_MODULE_NAME = 'notificationDashboard'

    if (!store.hasModule(NOTIFICATION_MODULE_NAME)) {
      store.registerModule(NOTIFICATION_MODULE_NAME, dashboardModule)

      onUnmounted(() => {
        if (store.hasModule(NOTIFICATION_MODULE_NAME)) store.unregisterModule(NOTIFICATION_MODULE_NAME)
      })
    }

    const notifications = ref([])
    const notificationsTotal = ref(0)
    const notificationsBadge = ref(0)
    const listNotificationUser = computed(() => notifications)
    const exeSyncNotificationUser = null
    const activeNotificationPush = ref(false)
    const disableNotificationPush = ref(false)

    return {
      NOTIFICATION_MODULE_NAME,

      notifications,
      notificationsTotal,
      notificationsBadge,
      listNotificationUser,
      exeSyncNotificationUser,
      activeNotificationPush,
      disableNotificationPush,

      configSettingsScroll,
    }
  },
}
</script>
