export default [
  {
    title: 'modules.growth.Crescimento', /* <i18n path="modules.growth.Crescimento"></i18n> */
    icon: 'BarChart2Icon',
    children: [
      {
        title: 'modules.growth.Power BI', /* <i18n path="modules.growth.Power BI"></i18n> */
        route: { name: 'growth-powerbi', params: {} },
        resource: 'growth_powerbi',
        action: 'list',
      },
    ],
  },
]
