export default [
  {
    title: 'modules.crm.CRM', /* <i18n path="modules.crm.CRM"></i18n> */
    icon: 'UsersIcon',
    children: [
      {
        title: 'modules.crm.Novo contacto', /* <i18n path="modules.crm.Novo contacto"></i18n> */
        route: { name: 'crm-leads-new-contact', params: {} },
        resource: 'crm_leads_contact',
        action: 'new',
      },
      {
        title: 'modules.crm.Novo Lead', /* <i18n path="modules.crm.Novo Lead"></i18n> */
        route: { name: 'crm-leads-new-lead-menu-choose', params: {} },
        resource: 'crm_leads',
        action: 'new',
      },
      {
        title: 'modules.crm.Nova referência', /* <i18n path="modules.crm.Nova referência"></i18n> */
        route: { name: 'crm-leads-new-reference', params: {} },
        resource: 'crm_leads_reference',
        action: 'new',
      },
      {
        title: 'modules.crm.Base de dados', /* <i18n path="modules.crm.Base de dados"></i18n> */
        route: { name: 'crm-leads-listview', params: {} },
        resource: 'crm_leads',
        action: 'list',
      },
      {
        title: 'modules.crm.Visitas', /* <i18n path="modules.crm.Visitas"></i18n> */
        route: { name: 'crm-visitas-listview', params: {} },
        disabled: false,
        resource: 'crm_visitas',
        action: 'list',
      },
      {
        title: 'modules.crm.Propostas', /* <i18n path="modules.crm.Propostas"></i18n> */
        route: { name: 'crm-proposals-listview', params: {} },
        resource: 'crm_propostas',
        action: 'list',
      },
    ],
  },
]
