export default [
  {
    title: 'modules.financial.Financeiro', /* <i18n path="modules.financial.Financeiro"></i18n> */
    icon: 'DollarSignIcon',
    children: [
      {
        title: 'modules.financial.Processos de crédito', /* <i18n path="modules.financial.Processos de crédito"></i18n> */
        route: { name: 'financial-listview-processos-credito', params: {} },
        resource: 'financial_listview_processoscredito',
        action: 'list',
      },
      {
        title: 'modules.financial.Conta corrente', /* <i18n path="modules.financial.Processos de crédito"></i18n> */
        route: { name: 'financial-listview-conta-corrente', params: {} },
        resource: 'financial_listview_contacorrente',
        action: 'list',
      },
    ],
  },
]
