import api from '@core-custom/api/api'
import apiConfig from '@core-custom/api/apiConfig'
import i18n from '@/libs/i18n'
import defaultState from './defaultState'

export default {
  namespaced: true,
  state: defaultState,
  getters: {
    listingsRestore: state => state.listingsRestore.value,
  },
  mutations: {
    resetState(state) {
      Object.assign(state, defaultState)
    },
    setListingsRestore(state, payload) {
      state.listingsRestore.value = payload
    },
  },
  actions: {
    // Obter as notificacoes criticas
    async getCriticalNotificantions({ commit }) {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}getNotificationDashboardCritical`)
          .then(response => {
            if (response.data.data !== undefined) {
              if (typeof response.data.data.listingRestore !== 'undefined') {
                commit('setListingsRestore', response.data.data.listingRestore)
              }

              resolve(true)
            } else {
              reject(new Error('Problema ao carregar as notificações críticas'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as notificações críticas')))
            }
          })
      })
    },

    // Obter os objetivos do utilizador
    async getGoalsByUser() {
      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}profileUser/loadTableObjectivosUserDashboard`)
          .then(response => {
            if ((typeof response.data !== 'undefined') && (typeof response.data.resultados !== 'undefined')) {
              resolve(response.data.resultados)
            } else {
              reject(new Error('Problema ao carregar os objetivos'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar os objetivos')))
            }
          })
      })
    },

    // Obter os imoveis para o dashboard
    async getListingsByTab(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('pageLoadSel', payload.tab)
        formData.append('limit', 5)
        formData.append('fsw012s64', 1)
        formData.append('fsw012s02', '')

        if (payload.tab === 'myHUB') {
          formData.append('fltMoradaMCByUser', 1)
        }

        api.post(`${apiConfig.url_base_api}listings/loadDashboardListings`, formData)
          .then(response => {
            if ((typeof response.data !== 'undefined') && (typeof response.data.listing !== 'undefined')) {
              let data = {}

              if (typeof response.data.listing === 'object') {
                if (Object.keys(response.data.listing).length > 0) {
                  data = response.data.listing
                }
              }

              resolve(data)
            } else {
              reject(new Error('Problema ao carregar as angariações'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as angariações')))
            }
          })
      })
    },

    // Obter os imoveis com descida de preço para o dashboard
    async getListingsPriceDrop(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('pageLoadSel', payload.tab)
        formData.append('limit', payload.limit)
        formData.append('fsw012s64', 1)
        formData.append('fsw012s02', '')

        if (payload.tab === 'myHUB') {
          formData.append('fltMoradaMCByUser', 1)
        }

        api.post(`${apiConfig.url_base_api}listings/loadDashboardListingsBaixas`, formData)
          .then(response => {
            if ((typeof response.data !== 'undefined') && (typeof response.data.listing !== 'undefined')) {
              let data = {}

              if (typeof response.data.listing === 'object') {
                if (Object.keys(response.data.listing).length > 0) {
                  data = response.data.listing
                }
              }

              resolve(data)
            } else {
              reject(new Error('Problema ao carregar as descidas de preço'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as descidas de preço')))
            }
          })
      })
    },

    // Obter os imoveis com descida de preço para o dashboard
    async getListingsWorking() {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('pageLoadSel', 'myHUB')
        formData.append('limit', 5)
        formData.append('fsw012s64', 1)
        formData.append('fsw012s02', '')
        formData.append('fltMoradaMCByUser', 1)

        api.post(`${apiConfig.url_base_api}listings/loadDashboardListingsWork`, formData)
          .then(response => {
            if ((typeof response.data !== 'undefined') && (typeof response.data.listing !== 'undefined')) {
              let data = {}

              if (typeof response.data.listing === 'object') {
                if (Object.keys(response.data.listing).length > 0) {
                  data = response.data.listing
                }
              }

              resolve(data)
            } else {
              reject(new Error('Problema ao carregar as angariações a trabalhar'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as angariações a trabalhar')))
            }
          })
      })
    },

    // Obter o periodo de faturacao do utilizador para o dashboard
    async getReportDashboardInvoice(state, payload) {
      return new Promise((resolve, reject) => {
        const formData = new FormData()
        formData.append('limitMonth', payload.limitMonth)

        api.post(`${apiConfig.url_base_api}power/dashboard/reports/invoice`, formData)
          .then(response => {
            if ((typeof response.data !== 'undefined') && (typeof response.data.invoice !== 'undefined')) {
              let data = {}

              if (typeof response.data.invoice === 'object') {
                if (Object.keys(response.data.invoice).length > 0) {
                  data = response.data.invoice
                }
              }

              resolve(data)
            } else {
              reject(new Error('Problema ao carregar o período de faturação'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar o período de faturação')))
            }
          })
      })
    },

    // Obter as notificacoes do utilizador para o dashboard
    async getNotificationByUser({ dispatch }) {
      await dispatch('request/cancelTokensPendingByUrl', 'notification/getNotificacoesByUser', { root: true })
      const request = new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}notification/getNotificacoesByUser`)
          .then(response => {
            if ((typeof response.data !== 'undefined') && (typeof response.data.msg !== 'undefined')) {
              resolve({ notifications: response.data.msg, total: Number(response.data.total) })
            } else {
              reject(new Error('Problema ao carregar as notificações'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao carregar as notificações')))
            }
          })
      })
      return request
    },

    async changeMarkStatusNotification({ dispatch }, payload) {
      dispatch('request/cancelTokensPending', null, { root: true })

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}notification/setStatus`, payload)
          .then(response => {
            if (response.data.error !== 1) {
              resolve(response.data)
            } else {
              reject(new Error('Problema a marcar/desmarcar notificações'))
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema a marcar/desmarcar notificações')))
            }
          })
      })
    },

    // Eliminar angariação em rascunho
    async deleteDraftsUser(state, payload) {
      const formData = new FormData()

      formData.append('sw012s01', payload.sw012s01)

      return new Promise((resolve, reject) => {
        api.post(`${apiConfig.url_base_api}listings/deleteDraftsUser`, formData)
          .then(response => {
            if (response.data.error === 1) {
              reject(new Error(response.data.msg))
            } else {
              resolve(response.data)
            }
          })
          .catch(error => {
            if ((error.response !== undefined) && (error.response.status !== undefined)) {
              reject(new Error(i18n.t('Problema ao eliminar angariação')))
            }
          })
      })
    },
  },
}
